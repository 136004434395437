// $modal-content-fallback-border-color: #999;

body.bob-frame-open .body-content {
  display: none;
}
.body-content {
  position: relative;
}

.bob-frame-container {
  padding: 0 30px;
}

.bob-frame {
  display: none;
  overflow: hidden;
  position: relative;
  padding-left: calc(100vw - 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1040;
  background: #ffffff;

  &.bob-fading-in {
    position: fixed;
    top: -1;
    left: 0;
  }

  .bob-window {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    border: 0 none;
    

    .bob-header {

      img {
        display: block;
        width: auto;
        // height: 44px;
        margin: 10px 0 0 0;
      }
    }

    .bob-close-vis {
      position: absolute;
      top: 13px;
      right: 19px;
      font-size: 40px;
      padding: 5px 14px 4px 14px;
      line-height: 39px;
      border: none;
      border-radius: 50px;
      background-color: #fff;
    
      &:focus,
      &:active {
        outline: 0 none;
        background-color: rgb(230, 230, 230);
        border-radius: 50px;
      }
    }
  }
}

.bob-msg {
  display: none;
  position: fixed;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 1080;
  // Remove focus outline from opened modal
  outline: none;
  border: 1px solid #999; //old browsers fallback (ie8 etc)
  border: 1px solid #999;
  border-radius: .3rem;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  background-color: #ffffff;
  cursor: pointer;

  .msg-content {
    display: none;
    border-radius: .3rem;
    padding: 30px 30px 30px 25px;

    &::after {
      display: block;
      content: "";
      clear: both;
    }

    i {
      float: left;
      width: 40px;
      font-size: 24px;
    }
    span {
      display: block;
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  .msg-success {
    i {
      color: #82D182; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-error {
    i {
      color: #d43f3a; // #a94442; // #d43f3a; // #E8AEB7;
    }
  }

  .msg-error-checksum {
    i {
      fill: #d43f3a; // #a94442; // #d43f3a; // #E8AEB7;
    }
  }

  .msg-warning {
    i {
      color: #ffc107; // #ffc107;
    }
  }

  .msg-close {
    position: absolute;
    top: 13px;
    right: 19px;
    font-size: 40px;
    padding: 5px 14px 4px 14px;
    line-height: 39px;
    border: none;
    border-radius: 50px;
  }

}

  body.bob-frame-open .body-content {  // this caused problems, i dont know why this was icludet in the first place
   display: none;
  }

  @media screen and (min-width: @screen-sm-min) {
  .bob-frame {
    .bob-window {
      position: relative;
      width: 600px;
      margin: 0 auto 20px;
    }
  }
  .bob-msg {
    top: 30px;


    .msg-close {
      // padding: 5px 10px;
    }
  }
  // body.bob-frame-open .body-content {  // this caused problems, i dont know why this was icludet in the first place
  //  display: block;
  // }
}

.bob-frame-content {
  display: none;
}


.form-group {

	.form-control.is-invalid {
		border: 1px solid #ff0000;
	}
}