// passage

// anchor helper
.passage-anchor {
	position: relative;
	top: -100px;
}
// .passage-anchor#health-providers-2,
// .passage-anchor#payers-2,
// .passage-anchor#employers-2,
// .passage-anchor#online-treatment {
//   top: -50px;
// }

// ********************

.passage {
  margin-top: 30px;
  margin-bottom: 30px;
  // padding-top: 30px;
  padding-bottom: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

@media screen and (min-width: @screen-sm-min) {
  .passage {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}


.no-break {
  white-space: nowrap;
}