// footer
// ******


// .footer margin-top should be 0, because of bottomizer

.footer {
	padding-bottom: 50px;
	background: #E7EEF2;

	.footer-column {
		padding-top: 50px;
		// color: #fff;

		a,
		a:hover {
			text-decoration: none;
			color: #333;
		}

		a {
			.opacity(0.7);
		}

		a:hover,
		a.active {
			.opacity(1);
		}

		ul {
			list-style: none;
			padding-left: 0;

			li {
				position: relative;
				padding-left: 13px;
			}

			.bullet {
				display: block;
				position: absolute;
				top: 11px;
				left: 0px;
				background: #fff;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				.opacity(0.5);
			}

		}

	}

	.footer-logo {
		height: 100px;

		.gaia-logo {
			margin-top: 16px;
		}
	}

	// .footer-imprint {
	// 	// p:first-child {
	// 	// 	margin-bottom: 24px;
	// 	// }
	// 	p:nth-child(n+4) {
	// 		font-size: 70%;
	// 		line-height: 1.3;
	// 		.opacity(0.5);
	// 	}
	// }

	.footer-legal {
		font-size: 70%;
		line-height: 1.3;
		.opacity(0.5);
		margin-bottom: 14px;
	}

	.footer-small {
		font-size: @font-size-small;
		line-height: @line-height-small;
		margin-bottom: 14px;
	}

	.footer-formal-links {
		margin-top: 14px;
	}

}

// show popovers (socials) if overlapping .container (desktop)
.footer .footer-links .container {
	overflow: visible;
}

// @media (min-width: @screen-tablet) {
//   .footer .footer-links .container {
//     overflow: visible;
//   }
// }

.footer-redirect {
	display: none;
}


.footer-btn {
	border: solid thin;
	padding: 10px;
	border-radius: 50px;
	font-size: 15px;

	&:hover {
		background-color: #eaeef1;
	}
}

.footer {
	.jumbotron-badges {
		.row {
			max-width: 150px;
		}
	}

	.badge-play {
		margin-top: 10px;
	}
}


@media screen and (min-width: @screen-sm-min) {

		.footer-links-float {
			display: table;
			float: right;
			width: 135px;
		}
}