// form



legend {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  border-bottom: none;
  padding-bottom: 6px;
  margin-bottom: 15px;
}

label {
  font-weight: 300;
}

input, select, textarea {
  font-family: @font-family-base;
  font-style: italic;
}

textarea.form-control {
  resize: vertical;
}

.form-control.error {
  border: 1px solid #ff0000;
}

