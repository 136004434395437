// jumbotron
// ***************


// temp styles to be removed when badges are added for appstore and playstore
.jumbotron-content.temp-styles {
  margin-top: 0px;
}


@media screen and (min-width: @screen-sm-min) {
  .jumbotron-content.temp-styles {
    margin-top: 70px;
  }

  .jumbotron {
    .jumbotron-kopfschmerz-logo.temp-styles {
      img {
        max-width: 145px;
        margin-top: 46px;
      }
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .jumbotron-content.temp-styles {
    margin-top: 70px;
  }

  .jumbotron {
    .jumbotron-kopfschmerz-logo.temp-styles {
      img {
        margin-top: 0px;
      }
    }
  }
}

.jumbotron-front {
  margin-top: 0; // 5px;
  margin-bottom: 0;
  padding: 0;
  background-image: url("/images/pFront_hero.jpg");
  background-size: cover;
  background-position: 58%;
  height: ~"-moz-calc(100% - 50px)";
  height: ~"-webkit-calc(100% - 50px)";
  height: ~"calc(100% - 50px)";

  .jumbotron-top {
    margin-top: 20px;
    position: relative;

    h3 {
      display: inline;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: italic;
      font-weight: light;
      font-size: (@font-size-base * 0.8);
    }

    .jumbotron-top-header {
      position: absolute;
      display: inline;
      left: 130px;    
      line-height: 0.8;
    }
  
    .jumbotron-logo {
      display: inline-block;
    }
  }

  // .jumbotron-content {
  
  //   h1 {
  //      width: 70%;
  //   }
  //   p {
  //     width: 50%;
  //   }

  // }

}
.jumbotron-front.container {
  position: relative;
  height: 100%;
}

.jumbotron-content {
  padding-top: 20px;
  z-index: 11;

  h1 {
    font-size: (@font-size-base * 1.5);
    margin-bottom: 4px;
    font-weight: 500;
    color: #343434;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }


  h3 {
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: (@font-size-base * 0.8);
  }

  h4 {
    display: none;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: (@font-size-base * 0.8);
    margin-bottom: 0;
  }

  p {
    font-size: (@font-size-base * 0.8);
    font-weight: normal;
    width: 70%;
  }

}

.jumbotron-link {
  margin: 6px 0 12px;
}

.jumbotron-logo {
  // position: absolute;
  // bottom: 15px;
  // right: 15px;

  img {
    width: 120px;
    height: auto;
  }
}

.jumbotron-content-block {
  width: 300px;
}

@media screen and (min-width: @screen-sm-min) {
  .jumbotron-front {
    background-position: center 10%;
    // min-height: 400px;
    height: 528px;
    // background-image: url("/images/rueckenfit_hero_desk.jpg");
    .jumbotron-top {
      margin-top: 43px;
    }
  
    .jumbotron-content {
      padding-top: 75px;

      h1 {
        font-size: (@font-size-base * 1.5);
        margin-bottom: 16px;
      }
      h3 {
        display: none;
      }
      h4 {
        display: block;
      }
      p {
        font-size: (@font-size-base * 1);
        width: 100%;
      }
    }
  }
}


@media screen and (min-width: @screen-lg-min) {
  .jumbotron-front {
    background-position: center 40%;
    // min-height: 400px;
    // height: 600px;
  }
}