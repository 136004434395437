.stripe {
    background-color: #FCEDE5;
    padding: 20px 0;
    .stripe-inner {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
    }
    .stripe-inner-logo {
        img {
            margin: 0 auto;
        }
    }

    .content {
        margin-top: -10px;
    }
    .btn-round {
		margin: 10px auto 0 auto;
    }
}
