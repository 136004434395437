// paragraph

.paragraphs .paragraph .container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  
  .paragraph-img {
        .desktop {
            display: none;
        }
   }

   .img-default {
    display: block;
    margin: 0 auto,
}

.paragraphs {
    .btn-round {
		width: 100%;
    }
}


.paragraph-copy {
    img {
        margin-bottom: 14px;
        margin-left: 2px;
    }
}

   @media (min-width: @screen-sm) {

    .paragraphs .paragraph .container {
        margin-top: 50px;
        margin-bottom: 50px;
      }


    .paragraphs {
        .desktop {
            display: block;
        }
    }
   }

  
