// ***************
// spots-usps

// #usps {
// 	position: relative;
// 	top: -50px;
// }

// ***************

.usps {
	margin: 50px 0;
	background-color: #fff;
	color: #333333;


	.row {

		
		.usp:nth-child(2),
		.usp:nth-child(3) {
			padding-top: 20px;
		}
	}

	h2 {
		font-size: (@font-size-h2 * 0.6);
		font-weight: 400;
		line-height: 1.5;
		padding-left: 8%;
		/* text-align: center; */
	}

	.usp-content {
		
		padding-left: 10%;

		li {
		list-style-image: url('/images/arrow.svg');
		}
	}
}

.display-up-sm, .display-only-sm {
	display: none;
}

@media screen and (min-width: @screen-sm-min) {
	.usps {
		margin-top: 0;
		background-color: #E7EEF2;
		color: #333333;

		.row {
			margin-left: 0;

			.usp {
				padding: 30px 0 23px 0;	
			}
			.usp:nth-child(2),
			.usp:nth-child(3) {
				padding: 30px 0 23px 0;

			}
		}
	
		h2 {
			font-size: (@font-size-h2 * 0.6);
			font-weight: 400;
			line-height: 1.5;
			padding-left: 0;
			/* text-align: center; */
		}

		.usp-content {
			
			padding-left: 0;

			li {
				list-style: none;
			}
		}

	}
	.display-up-sm, .display-only-sm {
		display: block;
	}
}


@media screen and (min-width: @screen-md-min) {
	.usps {

		.row {

			
			.usp:nth-child(2),
			.usp:nth-child(3) {
				margin-left: 0px;	

			}
		}
	
	}

	.display-only-sm {
		display: none;
	}
	
}

.page_2 {

@media screen and (min-width: @screen-md-min) {
	.usps {
		.usp:nth-child(3) {
			margin-left: 35px;
		}
	}
}

}
