.cta-item {
    min-width: 300px;
    width: 100%;
    margin: 10px auto;


    .accessform {
        padding: 30px;
    }

    .form-group-description {
        p {
            font-size: 13px;
        }
    }
    
}

@media screen and (min-width: @screen-sm-min) {
    .cta-item {
        width: 600px;
        // margin: 100px auto;
    }
}



// experimental styles

// .cta-item {
//     form {
//         margin: 0 auto;
        
//         // positioning context
//         > div {
//           position: relative;
//           background: white;
          
//           // Looks like placeholder
//         //   > label {
//         //     opacity: 0.3;
//         //     font-weight: bold;
//         //     position: absolute;
//         //     top: 22px;
//         //     left: 20px;
//         //   }
          
//           > input[type="text"],
//           > input[type="email"],
//           > input[type="phone"] {
//             width: 100%;
            
//             &:focus {
              
//               // removing default focus style
//               outline: 0;
//               // adding new one
//               background: white;
              
//               & + label {
//                 opacity: 0;
//               }
//             }
            
//             &:valid {
//               background-size: 20px;
//               background-repeat: no-repeat;
//               background-position: 20px 20px;
//               border: solid thin #79ca1d;
//               background-color: #fff;
//               & + label {
//                 opacity: 0;
//               }
//             }
            
//             &:invalid:not(:focus):not(:placeholder-shown) {
//               border: solid thin red;
//               & + label {
//                 opacity: 0;
//               }
//             }
            
//             &:invalid:focus:not(:placeholder-shown) {
//               & ~ .requirements {
//                 max-height: 200px;
//                 padding: 0 30px 20px 50px;
//               }
//             }
            
//           }
          
//           .requirements {
//             padding: 0 30px 0 50px;
//             color: #999;
//             max-height: 0;
//             transition: 0.28s;
//             overflow: hidden;
//             color: red;
//             font-style: italic;
//             position: absolute;
//             top: 0;
//             right: 0;
//           }
          
//         }
        
//         input[type="submit"] {
//           display: block;
//           width: 100%;
//           margin: 20px 0;
//           background: #41D873;
//           color: white;
//           border: 0;
//           padding: 20px;
//           font-size: 1.2rem;
//         }
        
//       }
      
// }