// custom.less

html,
body {
  height: 100%;
}

// This can break the bottomizer. Yet there might be situation when it should be included.


.page_1,
.page_2 {

  .body-content {
    // position: relative;
    height: 100%;
  }
}


// ********************
// button

.btn {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

// ********************
// bottom

div#bottom {
  top: 0px;
}

// ***************
//FORM

// .container-form {
// 	margin: 5vh auto 5vh auto;
// }

// .cta-item {
// 	position: relative;
// }

.success-content {
  display: block;
  padding: 70px 30px 100px 30px;
}

@media screen and (min-width: @screen-sm-min) {

  .success-content {
    padding: 130px 30px 0px 30px;
    min-height: 300px;
    
 }
}

// ********************
// nav button

.navbar-secondary .btn-nav,
.navbar-nav .btn-nav {
  margin-top: 7px;

  .button-variant(#ffffff;
  transparent;
  #ffffff);

  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical;
  @padding-small-horizontal;
  @font-size-small;
  @line-height-small;
  @btn-border-radius-small);
}

/* overide border-color */
.navbar-secondary .btn-nav:focus,
.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav.focus,
.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:hover,
.navbar-nav .btn-nav:hover {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active,
.navbar-nav .btn-nav:active,
.navbar-secondary .btn-nav.active,
.navbar-nav .btn-nav.active,
.open>.dropdown-toggle.navbar-secondary .btn-nav,
.open>.dropdown-toggle.navbar-nav .btn-nav {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active:hover,
.navbar-nav .btn-nav:active:hover,
.navbar-secondary .btn-nav.active:hover,
.navbar-nav .btn-nav.active:hover,
.open>.dropdown-toggle.navbar-secondary .btn-nav:hover,
.open>.dropdown-toggle.navbar-nav .btn-nav:hover,
.navbar-secondary .btn-nav:active:focus,
.navbar-nav .btn-nav:active:focus,
.navbar-secondary .btn-nav.active:focus,
.navbar-nav .btn-nav.active:focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav:focus,
.open>.dropdown-toggle.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav:active.focus,
.navbar-nav .btn-nav:active.focus,
.navbar-secondary .btn-nav.active.focus,
.navbar-nav .btn-nav.active.focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav.focus,
.open>.dropdown-toggle.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

/* overide border-color - end */

.navbar-secondary {
  display: table;
  position: relative;
  float: right;
  padding-right: 15px;

  a {
    color: #ffffff;
  }

  // .navbar-secondary {
  //   a, a:hover,
  //   li > a, a:hover {
  //     .opacity(1);
  //   }
  //   li > a {
  //     .opacity(0.7);
  //   }

  //   li > a > i:before {
  //     padding: 0 5px 0 5px;
  //   }

  //   // li.open > a {
  //   //   background-color: transparent;
  //   //   color: #fff;
  //   // }
  // }

  // do not show 
  // .navbar-secondary li > a:focus {
  //   outline: none 0;
  // }
}

@media screen and (min-width: @screen-sm-min) {
  .navbar-secondary {
    display: none;
  }
}

.navbar-btn-descr {
  display: inline-block;
  width: 150px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  padding-top: 13px;
  color: #fff;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid #F89962;
  color: #F89962;
  cursor: pointer;

  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #F89962;
  }
}

// ********************
// scrolltop

a#scrolltop {
	position: fixed;
	z-index: 900;
	bottom: 20px;
	right: 20px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	// background-color: @brand-app;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);

  display: none;
  transition: all 0.3s ease;

	span {
		position: relative;
		margin: 5px 0 0 7px;
		color: #fff;
		font-size: 20px;

		transition: all 0.3s ease;
	}
}
a#scrolltop:hover {
	text-decoration: none;
}