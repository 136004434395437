// jumbotron
// ***************

.jumbotron-coach {
  margin-top: 0; // 5px;
  margin-bottom: 0;
  padding: 0;
  background-image: url("/images/pFront_hero.jpg");
  background-size: cover;
  background-position: 76%;
  height: ~"-moz-calc(100% - 50px)";
  height: ~"-webkit-calc(100% - 50px)";
  height: ~"calc(100% - 50px)";

  .container {
    position: relative;
    height: 100%;
  }

  .jumbotron-kopfschmerz-logo {
    position: static;

    img {
      width: 70px;
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }

  .jumbotron-content {
  
    h1 {
       width: 70%;
    }
    p {
      width: 80%;
    }

    .jumbotron-badges.desktop {
      display: none;
    }
  }

  .jumbotron-badges {
    width: 140px;
    position: absolute;
    left: 15px;
    bottom: 15px;

    p {
      font-size: (@font-size-base * 0.8);
      font-weight: normal;
      margin-bottom: 0;
    }

    .badge {
      margin: 5px 0 0;
    }
  }

  .jumbotron-badges.mobile {
    display: block;
  }
  
  
}



.jumbotron-content {
  padding-top: 80px;
  z-index: 11;

  h1 {
    font-size: (@font-size-base * 1.5);
    margin-bottom: 4px;
  }

  h3 {
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: (@font-size-base * 0.8);
  }

  h4 {
    display: none;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: (@font-size-base * 0.8);
    margin-bottom: 0;
  }

  p {
    font-size: (@font-size-base * 0.8);
    font-weight: normal;
  }

}

.jumbotron-content-inner {
  img {
    margin-left: 4px;
  }
}

.jumbotron-link {
  margin: 6px 0 12px;
}


.jumbotron-content-block {
  width: 300px;
}

@media screen and (min-width: @screen-sm-min) {
  .jumbotron-coach {
    background-position: center 10%;
    height: 528px;

    .jumbotron-content {
      padding-top: 100px;

      h1 {
        font-size: (@font-size-base * 2);
        width: 100%;
      }

      h3 {
        display: none;
      }

      h4 {
        display: block;
      }

      p {
        font-size: (@font-size-base * 1);
        width: 100%;
      }
      
      .jumbotron-badges.desktop {
        display: block;
      }
    }

    .jumbotron-badges.mobile {
      display: none;
    }
    

    .jumbotron-kopfschmerz-logo {
      position: relative;

      img {
        position: relative;
        padding-top: 120px;
        width: 100%;
        max-width: 170px;
      }
    }

    .jumbotron-badges {
      width: 100%;
      position: relative;
      left: 0;
      bottom: 0;
      margin-top: 30px;
    
      p {
        font-size: (@font-size-base * 0.8);
        font-weight: normal;
        margin-bottom: 0;
      }

      .badge {
        margin: 15px 0;
      }
    }
  }
}


@media screen and (min-width: 1800px) {
  .jumbotron-coach {
    background-image: url("/images/pFront_hero_lg.jpg");
 }
}