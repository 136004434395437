// custom button colors
@btn-custom-color:   #fff;
@btn-custom-bg:      @brand-custom;
@btn-custom-border:  @brand-custom;
@btn-radius: 35px;

.btn-bob {
	.bob-variant(@btn-custom-color; @btn-custom-bg; @btn-custom-border; @btn-radius);
	cursor: pointer;
	display: block;
	font: 700 19px/70px "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-align: center;
	height: 50px;
	line-height: 50px;
	overflow: hidden;
	position: relative;
	-webkit-transition: background .3s ease-in-out;
	transition: background .3s ease-in-out;
	will-change: background;
	max-width: 300px;
	margin-top: 20px;
	padding: 0;
  
	&:hover,
	&:active,
	&:focus {
	  color: @btn-custom-color;
	  background-image: none;
	  text-decoration: none;
	  box-shadow: none;
  }
}

.bob-variant(@color; @background; @border; @radius) {
  color: @color;
  background-color: @background;
  border-color: @border;
  border-radius: @radius;
  }

// .btn-round {
//   cursor: pointer;
//   display: block;
//   font: 700 14px/70px sans-serif;
//   text-transform: uppercase;
//   height: 70px;
//   left: 50%;
//   overflow: hidden;
//   position: relative;
//   margin-top: 100px;
//   top: 50%;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   -webkit-transition: background .3s ease-in-out;
//   transition: background .3s ease-in-out;
//   will-change: background;
//   max-width: 300px;
//   width: 100%;
//   display: block;
  
//   a {
//     color: #fff;
//   }
// }

//.btn-round:hover {background: #F89962;}

.click {
  border-radius: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}
.click.feedback {animation: feedback .6s ease-out;}

@keyframes feedback {
  0% {
    background: rgba(255,255,255,0.2);
    height: 0px;
    width: 0px;
  }
  100% {
    background: rgba(255,255,255,0);
    height: 400px;
    width: 400px;
  }
}

.btn-center {
	left: 50%;
	top: 20px;
  	-webkit-transform: translate(-50%, -50%);
 	 transform: translate(-50%, -50%);
}